const CartIcon = ({color}: {color: string}) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_1510)">
        <path
          d="M4.00194 10.9081L3.1737 10.816L4.00194 10.9081ZM2.70564 22.5748L3.53387 22.6668L2.70564 22.5748ZM22.2944 22.5748L21.4662 22.6668L22.2944 22.5748ZM20.998 10.9081L21.8264 10.816L20.998 10.9081ZM3.1737 10.816L1.8774 22.4828L3.53387 22.6668L4.83017 11.0001L3.1737 10.816ZM4.3621 25.2588H20.6379V23.5921H4.3621V25.2588ZM23.1225 22.4828L21.8264 10.816L20.1699 11.0001L21.4662 22.6668L23.1225 22.4828ZM19.3415 8.59212H5.6584V10.2588H19.3415V8.59212ZM21.8264 10.816C21.6857 9.54996 20.6155 8.59212 19.3415 8.59212V10.2588C19.7662 10.2588 20.1229 10.5781 20.1699 11.0001L21.8264 10.816ZM20.6379 25.2588C22.1279 25.2588 23.2872 23.9636 23.1225 22.4828L21.4662 22.6668C21.521 23.1605 21.1345 23.5921 20.6379 23.5921V25.2588ZM1.8774 22.4828C1.71285 23.9636 2.87209 25.2588 4.3621 25.2588V23.5921C3.86544 23.5921 3.47902 23.1605 3.53387 22.6668L1.8774 22.4828ZM4.83017 11.0001C4.87707 10.5781 5.23379 10.2588 5.6584 10.2588V8.59212C4.38454 8.59212 3.31437 9.54996 3.1737 10.816L4.83017 11.0001ZM8.33334 6.92546V6.09212H6.66667V6.92546H8.33334ZM16.6667 6.09212V6.92546H18.3334V6.09212H16.6667ZM12.5 1.92546C14.8012 1.92546 16.6667 3.79094 16.6667 6.09212H18.3334C18.3334 2.87046 15.7217 0.258789 12.5 0.258789V1.92546ZM8.33334 6.09212C8.33334 3.79094 10.1988 1.92546 12.5 1.92546V0.258789C9.27834 0.258789 6.66667 2.87046 6.66667 6.09212H8.33334Z"
          fill={color || 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_1510">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.258789)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CartIcon;
