const PlusIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`desktop:w-[16px] laptop:w-[16px] tablet:w-[16px] mobile:w-[10px] old:w-[10px]`}
    >
      <path
        d="M9.41406 3.35449H10.5859C10.6901 3.35449 10.7422 3.40658 10.7422 3.51074V17.2607C10.7422 17.3649 10.6901 17.417 10.5859 17.417H9.41406C9.3099 17.417 9.25781 17.3649 9.25781 17.2607V3.51074C9.25781 3.40658 9.3099 3.35449 9.41406 3.35449Z"
        fill="#3A264B"
      />
      <path
        d="M3.4375 9.64355H16.5625C16.6667 9.64355 16.7188 9.69564 16.7188 9.7998V10.9717C16.7188 11.0758 16.6667 11.1279 16.5625 11.1279H3.4375C3.33333 11.1279 3.28125 11.0758 3.28125 10.9717V9.7998C3.28125 9.69564 3.33333 9.64355 3.4375 9.64355Z"
        fill="#3A264B"
      />
    </svg>
  );
};

export default PlusIcon;
