const SelectArrowIcon = () => {
  return (
    <svg
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6.38574L0.535898 0.385742L7.4641 0.385742L4 6.38574Z"
        fill="#3A264B"
      />
    </svg>
  );
};

export default SelectArrowIcon;
