import {
  useParams,
  Form,
  Await,
  useMatches,
} from '@remix-run/react';
import {useWindowScroll} from 'react-use';
import {Disclosure} from '@headlessui/react';
import {PropsWithChildren, Suspense, useCallback, useEffect, useMemo, useState} from 'react';

import {
  Drawer,
  useDrawer,
  Text,
  Input,
  IconLogin,
  IconAccount,
  IconBag,
  IconSearch,
  Heading,
  IconMenu,
  IconCaret,
  Cart,
  CartLoading,
  Link,
} from '~/components';
import {
  type EnhancedMenu,
  type EnhancedMenuItem,
  useIsHomePath,
} from '~/lib/utils';
import {useIsHydrated} from '~/hooks/useIsHydrated';
import {useCartFetchers} from '~/hooks/useCartFetchers';
import type {LayoutData} from '../root';
import Header from '~/components/new/Header';
import {COLOR} from '~/constants';
import Footer from '~/components/new/Footer';

export interface ILayout extends PropsWithChildren {
  layout: LayoutData;
}


const CookieBanner = () => {

  const [accepted, setAccepted] = useState<boolean>(true);
  const [rejected, setRejected] = useState(false);

  useEffect(() => {
    setAccepted(!!localStorage?.getItem('cookie'));
  }, [])

  const handleAccept = useCallback(() => {
    setAccepted(true);
    localStorage.setItem('cookie', 'true');
  }, []);

  const handleReject = () => {
    setRejected(true);
  };
  return(
    <>
      {
        !accepted &&
        <div className={`fixed h-full w-full z-[100] bg-black bg-opacity-25 opacity-100`}>
          {
            !rejected &&
            <div
              className={`
            flex
            flex-col
            gap-[25px]
            absolute
            bottom-0
            bg-white
            text-violet
            w-full
            desktop:px-[100px] laptop:px-[100px] tablet:px-[80px]
            desktop:py-[40px] laptop:py-[40px] tablet:py-[30px]
            mobile:px-[15px] old:px-[15px]
            mobile:py-[20px] old:py-[20px]
        `}
            >

              <span className={'font-graphik semibold desktop:text-28 laptop:text-28 tablet:text-24 mobile:text-18 old:text-18'}>Cookies</span>
              <div className={'flex desktop:flex-row gap-[18px] laptop:flex-row tablet:flex-row mobile:flex-col old:flex-col justify-between'}>
                <span className={'desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-12 old:text-12 font-graphik flex-2'}>
                  This cookie policy is provided for the site florieintimates.com (Site). The document was drawn up taking into account the provisions of the European Regulation 679/2016 regarding the protection of personal data (GDPR), the Privacy Code (Legislative Decree 30 June 2003 n. 196) and the Guidelines of the Privacy Guarantor (especially the Guidelines on the use of cookies issued on July 10, 2021).
                </span>

                <div className={'flex flex-col flex-1 items-center gap-[14px]'}>
                  <button
                    onClick={handleAccept}
                    className={
                      'desktop:text-14 laptop:text-14 tablet:text-14 mobile:text-12 old:text-12 desktop:w-[60%] text-white bg-violet laptop:w-[60%] tablet:w-[100%] mobile:w-[100%] old:w-[100%] text-center font-graphik desktop:min-h-[40px] laptop:min-h-[40px] tablet:min-h-[35px] mobile:min-h-[45px] old:min-h-[45px]'
                    }
                  >
                    ACCEPT ALL
                  </button>
                  <button
                    onClick={handleReject}
                    className={
                      'desktop:text-14 laptop:text-14 tablet:text-14 mobile:text-12 old:text-12 desktop:w-[60%] text-violet bg-white border border-1 border-violet laptop:w-[60%] tablet:w-[100%] mobile:w-[100%] old:w-[100%] text-center font-graphik desktop:min-h-[40px] laptop:min-h-[40px] tablet:min-h-[35px] mobile:min-h-[45px] old:min-h-[45px]'
                    }
                  >
                    REJECT ALL
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  );
};
export function Layout({children, layout}: ILayout) {
  const isHome = useIsHomePath();

  const {
    isOpen: isMenuOpen,
    openDrawer: openMenu,
    closeDrawer: closeMenu,
  } = useDrawer();

  const {
    isOpen: isCartOpen,
    openDrawer: openCart,
    closeDrawer: closeCart,
  } = useDrawer();

  const addToCartFetchers = useCartFetchers('ADD_TO_CART');

  // toggle cart drawer when adding to cart
  // useEffect(() => {
  //   if (isCartOpen || !addToCartFetchers.length) return;
  //   openCart();
  // }, [addToCartFetchers, isCartOpen, openCart]);
  return (
    <>
      <CookieBanner />
      <div
        className="flex flex-col min-h-screen"
        style={{backgroundColor: COLOR.WHITE}}
      >
        <div className="">
          <a href="#mainContent" className="sr-only">
            Skip to content
          </a>
        </div>
        <CartDrawer isOpen={isCartOpen} onClose={closeCart} />
        <Header
          mutatePosition={isHome}
          mobileMenu={layout?.headerMenu}
          openCart={openCart}
        />
        <main role="main" id="mainContent" className="flex-grow">
          {children}
        </main>
      </div>
      <Footer menu={layout?.footerMenu} />
    </>
  );
}

function CartDrawer({isOpen, onClose}: {isOpen: boolean; onClose: () => void}) {
  const [root] = useMatches();

  return (
    <Drawer open={isOpen} onClose={onClose} heading="Carrello" openFrom="right">
      <div className="grid">
        <Suspense fallback={<CartLoading />}>
          <Await resolve={root.data?.cart}>
            {(cart) => <Cart layout="drawer" onClose={onClose} cart={cart} />}
          </Await>
        </Suspense>
      </div>
    </Drawer>
  );
}

export function MenuDrawer({
  isOpen,
  onClose,
  menu,
}: {
  isOpen: boolean;
  onClose: () => void;
  menu: EnhancedMenu;
}) {
  return (
    <Drawer open={isOpen} onClose={onClose} openFrom="left">
      <div className="grid">
        <MenuMobileNav menu={menu} onClose={onClose} />
      </div>
    </Drawer>
  );
}

function MenuMobileNav({
  menu,
  onClose,
}: {
  menu: EnhancedMenu;
  onClose: () => void;
}) {
  return (
    <nav className="grid gap-4 p-6 sm:gap-6 sm:px-12 sm:py-8 justify-center text-center">
      {/* Top level menu items */}
      {(menu?.items || []).map((item) => (
        <span key={item.id} className="block">
          <Link
            to={item.to}
            target={item.target}
            onClick={onClose}
            className={({isActive}) =>
              isActive
                ? 'pb-1 border-b -mb-px  pointer-events-auto '
                : 'pb-1  pointer-events-auto '
            }
          >
            <Text as="span" className={'uppercase'} size="copy">
              {item.title}
            </Text>
          </Link>
        </span>
      ))}
    </nav>
  );
}

function MobileHeader({
  title,
  isHome,
  openCart,
  openMenu,
}: {
  title: string;
  isHome: boolean;
  openCart: () => void;
  openMenu: () => void;
}) {
  // useHeaderStyleFix(containerStyle, setContainerStyle, isHome);

  const params = useParams();

  return (
    <header
      role="banner"
      className={`${
        isHome
          ? 'bg-primary/80 dark:bg-contrast/60 text-contrast dark:text-primary shadow-darkHeader'
          : 'bg-contrast/80 text-primary'
      } flex lg:hidden items-center h-nav sticky backdrop-blur-lg z-40 top-0 justify-between w-full leading-none gap-4 px-4 md:px-8`}
    >
      <div className="flex items-center justify-start w-full gap-4">
        <button
          onClick={openMenu}
          className="relative flex items-center justify-center w-8 h-8"
        >
          <IconMenu />
        </button>
        <Form
          method="get"
          action={params.locale ? `/${params.locale}/search` : '/search'}
          className="items-center gap-2 sm:flex"
        >
          <button
            type="submit"
            className="relative flex items-center justify-center w-8 h-8"
          >
            <IconSearch />
          </button>
          <Input
            className={
              isHome
                ? 'focus:border-contrast/20 dark:focus:border-primary/20'
                : 'focus:border-primary/20'
            }
            type="search"
            variant="minisearch"
            placeholder="Search"
            name="q"
          />
        </Form>
      </div>

      <Link
        className="flex items-center self-stretch leading-[3rem] md:leading-[4rem] justify-center flex-grow w-full h-full"
        to="/"
      >
        <Heading
          className="font-bold text-center leading-none"
          as={isHome ? 'h1' : 'h2'}
        >
          {title}
        </Heading>
      </Link>

      <div className="flex items-center justify-end w-full gap-4">
        <AccountLink className="relative flex items-center justify-center w-8 h-8" />
        <CartCount isHome={isHome} openCart={openCart} />
      </div>
    </header>
  );
}

function DesktopHeader({
  isHome,
  menu,
  openCart,
  title,
}: {
  isHome: boolean;
  openCart: () => void;
  menu?: EnhancedMenu;
  title: string;
}) {
  const params = useParams();
  const {y} = useWindowScroll();
  return (
    <header
      role="banner"
      className={`${
        isHome
          ? 'bg-primary/80 dark:bg-contrast/60 text-contrast dark:text-primary shadow-darkHeader'
          : 'bg-contrast/80 text-primary'
      } ${
        !isHome && y > 50 && ' shadow-lightHeader'
      } hidden h-nav lg:flex items-center sticky transition duration-300 backdrop-blur-lg z-40 top-0 justify-between w-full leading-none gap-8 px-12 py-8`}
    >
      <div className="flex gap-12">
        <Link className="font-bold" to="/" prefetch="intent">
          {title}
        </Link>
        <nav className="flex gap-8">
          {/* Top level menu items */}
          {(menu?.items || []).map((item) => (
            <Link
              key={item.id}
              to={item.to}
              target={item.target}
              prefetch="intent"
              className={({isActive}) =>
                isActive ? 'pb-1 border-b -mb-px' : 'pb-1'
              }
            >
              {item.title}
            </Link>
          ))}
        </nav>
      </div>
      <div className="flex items-center gap-1">
        {/*<Form*/}
        {/*  method="get"*/}
        {/*  action={params.locale ? `/${params.locale}/search` : '/search'}*/}
        {/*  className="flex items-center gap-2"*/}
        {/*>*/}
        {/*  <Input*/}
        {/*    className={*/}
        {/*      isHome*/}
        {/*        ? 'focus:border-contrast/20 dark:focus:border-primary/20'*/}
        {/*        : 'focus:border-primary/20'*/}
        {/*    }*/}
        {/*    type="search"*/}
        {/*    variant="minisearch"*/}
        {/*    placeholder="Search"*/}
        {/*    name="q"*/}
        {/*  />*/}
        {/*  <button*/}
        {/*    type="submit"*/}
        {/*    className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"*/}
        {/*  >*/}
        {/*    <IconSearch />*/}
        {/*  </button>*/}
        {/*</Form>*/}
        {/*<AccountLink className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5" />*/}
        <CartCount isHome={isHome} openCart={openCart} />
      </div>
    </header>
  );
}

function AccountLink({className}: {className?: string}) {
  const [root] = useMatches();
  const isLoggedIn = root.data?.isLoggedIn;
  return isLoggedIn ? (
    <Link to="/account" className={className}>
      <IconAccount />
    </Link>
  ) : (
    <Link to="/account/login" className={className}>
      <IconLogin />
    </Link>
  );
}

function CartCount({
  isHome,
  openCart,
}: {
  isHome: boolean;
  openCart: () => void;
}) {
  const [root] = useMatches();

  return (
    <Suspense fallback={<Badge count={0} dark={isHome} openCart={openCart} />}>
      <Await resolve={root.data?.cart}>
        {(cart) => (
          <Badge
            dark={isHome}
            openCart={openCart}
            count={cart?.totalQuantity || 0}
          />
        )}
      </Await>
    </Suspense>
  );
}

function Badge({
  openCart,
  dark,
  count,
}: {
  count: number;
  dark: boolean;
  openCart: () => void;
}) {
  const isHydrated = useIsHydrated();

  const BadgeCounter = useMemo(
    () => (
      <>
        <IconBag />
        <div
          className={`${
            dark
              ? 'text-primary bg-contrast dark:text-contrast dark:bg-primary'
              : 'text-contrast bg-primary'
          } absolute bottom-1 right-1 text-[0.625rem] font-medium subpixel-antialiased h-3 min-w-[0.75rem] flex items-center justify-center leading-none text-center rounded-full w-auto px-[0.125rem] pb-px`}
        >
          <span>{count || 0}</span>
        </div>
      </>
    ),
    [count, dark],
  );

  return isHydrated ? (
    <button
      onClick={openCart}
      className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
    >
      {BadgeCounter}
    </button>
  ) : (
    <Link
      to="/cart"
      className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
    >
      {BadgeCounter}
    </Link>
  );
}

const FooterLink = ({item}: {item: EnhancedMenuItem}) => {
  if (item.to.startsWith('http')) {
    return (
      <a href={item.to} target={item.target} rel="noopener noreferrer">
        {item.title}
      </a>
    );
  }

  return (
    <Link to={item.to} target={item.target} prefetch="intent">
      {item.title}
    </Link>
  );
};

function FooterMenu({menu}: {menu?: EnhancedMenu}) {
  const styles = {
    section: 'grid gap-4',
    nav: 'grid gap-2 pb-6',
  };

  return (
    <>
      {(menu?.items || []).map((item: EnhancedMenuItem) => (
        <section key={item.id} className={styles.section}>
          <Disclosure>
            {({open}) => (
              <>
                <Disclosure.Button className="text-left md:cursor-default">
                  <Heading className="flex justify-between" size="lead" as="h3">
                    {item.title}
                    {item?.items?.length > 0 && (
                      <span className="md:hidden">
                        <IconCaret direction={open ? 'up' : 'down'} />
                      </span>
                    )}
                  </Heading>
                </Disclosure.Button>
                {item?.items?.length > 0 ? (
                  <div
                    className={`${
                      open ? `max-h-48 h-fit` : `max-h-0 md:max-h-fit`
                    } overflow-hidden transition-all duration-300`}
                  >
                    <Suspense data-comment="This suspense fixes a hydration bug in Disclosure.Panel with static prop">
                      <Disclosure.Panel static>
                        <nav className={styles.nav}>
                          {item.items.map((subItem) => (
                            <FooterLink key={subItem.id} item={subItem} />
                          ))}
                        </nav>
                      </Disclosure.Panel>
                    </Suspense>
                  </div>
                ) : null}
              </>
            )}
          </Disclosure>
        </section>
      ))}
    </>
  );
}
