import {useIsHydrated} from '~/hooks/useIsHydrated';
import {FC, useEffect, useMemo} from 'react';
import {IconBag, Link} from '~/components';
import {COLOR} from '~/constants';
import CartIcon from '~/components/new/Icons/CartIcon';

export interface CartButtonProps {
  count: number;
  color: COLOR;
  openCart: () => void;
}
const CartButton: FC<CartButtonProps> = ({count, color, openCart}) => {
  const isHydrated = useIsHydrated();

  const BadgeCounter = useMemo(
    () => (
      <div
        className={`
          desktop:w-[21.28px] desktop:h-[25px]
          laptop:w-[21.28px] laptop:h-[25px]
          tablet:w-[16.15px] tablet:h-[20px]
          mobile:w-[12.86px] mobile:h-[15px]
          old:w-[12.86px] old:h-[15px]
          mb-[5px]
        `}
      >
        <CartIcon color={color} />
        <div
          className={`
          ${color === '#3A264B' ? 'bg-primary' : 'bg-white'}
          ${color === '#3A264B' ? 'text-white' : 'text-violet'}
          font-graphik
          desktop:text-14
          laptop:text-14
          tablet:text-12
          mobile:text-9
          old:text-9
          absolute
          desktop:right-[-12px]
          laptop:right-[-12px]
          tablet:right-[-9px]
          mobile:right-[-7px]
          old:right-[-7px]
          desktop:bottom-[-8px]
          laptop:bottom-[-8px]
          tablet:bottom-[-6px]
          mobile:bottom-[-5px]
          old:bottom-[-5px]
          subpixel-antialiased
          flex items-center
          justify-center
          leading-none
          text-center
          rounded-full
          desktop:w-[23px]
          laptop:w-[23px]
          tablet:w-[18px]
          mobile:w-[14px]
          old:w-[14px]
          desktop:h-[23px]
          laptop:h-[23px]
          tablet:h-[18px]
          mobile:h-[14px]
          old:h-[14px]
          `}
        >
          <span>{count || 0}</span>
        </div>
      </div>
    ),
    [count, color],
  );

  return isHydrated ? (
    <button
      onClick={openCart}
      style={{zIndex: 51}}
      className="relative
      flex
      items-center
      justify-center
      desktop:w-[21.28px] desktop:h-[25px]
      laptop:w-[21.28px] laptop:h-[25px]
      tablet:w-[16.15px] tablet:h-[20px]
      mobile:w-[12.86px] mobile:h-[15px]
      old:w-[12.86px] old:h-[15px]
      focus:ring-primary/5"
    >
      {BadgeCounter}
    </button>
  ) : (
    <Link
      to="/cart"
      className={`
        relative flex items-center justify-center
        desktop:w-[21.28px] desktop:h-[25px]
        laptop:w-[21.28px] laptop:h-[25px]
        tablet:w-[16.15px] tablet:h-[20px]
        mobile:w-[12.86px] mobile:h-[15px]
        old:w-[12.86px] old:h-[15px] focus:ring-primary/5`}
      style={{zIndex: 51}}
    >
      {BadgeCounter}
    </Link>
  );
};

export default CartButton;
