import {FC, Suspense, useCallback} from 'react';
import BurgerMenuButton from '~/components/new/BurgerMenuButton';
import {useHeaderStyles} from '~/hooks/useHeaderStyles';
import {Await, Link, useMatches} from '@remix-run/react';
import {COLOR, ROUTE} from '~/constants';
import LogoIcon from '~/components/new/Icons/LogoIcon';
import CartButton from '~/components/new/CartButton';
import {useDrawer} from '~/components';
import {Menu} from '@shopify/hydrogen/dist/storefront-api-types';
import {EnhancedMenuItem} from '~/lib/utils';

export interface EnhancedMenu extends Menu {
  items: EnhancedMenuItem[];
}
export interface HeaderProps {
  mutatePosition?: boolean;
  mobileMenu?: EnhancedMenu;
  openCart: () => void;
}

const Header: FC<HeaderProps> = ({mutatePosition, mobileMenu, openCart}) => {
  const [root] = useMatches();
  const {left, right, position} = useHeaderStyles();
  const {
    isOpen: isMenuOpen,
    openDrawer: openMenu,
    closeDrawer: closeMenu,
  } = useDrawer();

  const handleClickBurger = useCallback(() => {
    isMenuOpen ? closeMenu() : openMenu();
  }, [isMenuOpen]);

  return (
    <div
      style={{zIndex: 51}}
      className={`
        w-full
        items-center
        desktop:px-[7vw]
        laptop:px-[7vw]
        tablet:px-[4vw]
        mobile:px-[16px] mobile:pl-0
        old:px-[16px] old:pl-0
        flex
        justify-between
        xl:lg:pt-[45px]
        sm:pt-[42px]
        xs:pt-[42px]
        max-h-[120px]
        ${
          mutatePosition
            ? 'desktop:absolute laptop:absolute tablet:absolute mobile:relative old:relative'
            : position
        }
      `}
    >
      <BurgerMenuButton
        color={left}
        menu={mobileMenu}
        handleClick={handleClickBurger}
        isOpen={isMenuOpen}
        handleClose={closeMenu}
      />
      <div
        className={
          'gap-x-[38px] xl:flex lg:flex md:flex sm-only:hidden xs-only:hidden'
        }
        style={{color: isMenuOpen ? COLOR.VIOLET : left}}
      >
        <Link to={ROUTE.ABOUT} className={'uppercase font-graphik'}>
          About
        </Link>
        <Link to={ROUTE.CATALOG} className={'uppercase font-graphik'}>
          Catalogo
        </Link>
        <Link to={ROUTE.SUMISURA} className={'uppercase font-graphik'}>
          Intimo su misura
        </Link>
      </div>

      <a
        href={ROUTE.HOME}
        className={`
          flex desktop:mr-[170px] laptop:mr-[170px] tablet:mr-[100px] xl:w-40 lg-only:w-40 md-only:w-40 sm-only:w-20 xs-only:w-20
        `}
        style={{zIndex: 51}}
      >
        <LogoIcon
          height={'100%'}
          width={'100%'}
          color={isMenuOpen ? COLOR.VIOLET : left}
        />
      </a>

      <div className={'flex gap-x-[40px] justify-between items-end'}>
        <div
          className={
            'flex gap-x-[40px] items-center xl-only:flex lg-only:flex md-only:flex sm-only:hidden xs-only:hidden'
          }
          style={{color: right}}
        >
          <Link to={ROUTE.FAQ} className={'uppercase font-graphik'}>
            FAQ
          </Link>
          <Link to={ROUTE.BLOG} className={'uppercase font-graphik'}>
            Blog
          </Link>
        </div>
        <Suspense
          fallback={
            <CartButton
              openCart={openCart}
              count={0}
              color={isMenuOpen ? COLOR.VIOLET : right}
            />
          }
        >
          <Await resolve={root.data?.cart}>
            {(cart) => (
              <CartButton
                openCart={openCart}
                count={cart?.totalQuantity || 0}
                color={isMenuOpen ? COLOR.VIOLET : right}
              />
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
};

export default Header;
