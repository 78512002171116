import {Fragment, ReactNode, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';

import {Heading, IconClose} from '~/components';

/**
 * Drawer component that opens on user click.
 * @param heading - string. Shown at the top of the drawer.
 * @param open - boolean state. if true opens the drawer.
 * @param onClose - function should set the open state.
 * @param openFrom - right, left
 * @param children - react children node.
 */
export function Drawer({
  heading,
  open,
  onClose,
  openFrom = 'right',
  children,
}: {
  heading?: string;
  open: boolean;
  onClose: () => void;
  openFrom: 'right' | 'left';
  children: ReactNode;
}) {
  const offScreen = {
    right: 'translate-x-full',
    left: '-translate-x-full',
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`relative ${openFrom === 'right' ? 'z-52' : 'z-50'}`}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 left-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div
          className={`fixed inset-0 ${
            openFrom === 'right' ? '' : 'pointer-events-none'
          }`}
          style={{zIndex: openFrom === 'right' ? 52 : 5}}
        >
          <div
            className="absolute inset-0 overflow-hidden"
            style={{zIndex: openFrom === 'right' ? 52 : 5}}
          >
            <div
              style={{zIndex: openFrom === 'right' ? 52 : 5}}
              className={`inset-y-0 flex max-w-full items-center justify-center z-10 absolute ${
                openFrom === 'right' ? 'right-[0]' : 'left-[0]'
              } `}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300"
                enterFrom={'opacity-0'}
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Panel className="flex flex-col w-screen justify-center max-w-[720px] text-left align-middle transition-all transform shadow-xl h-screen-dynamic bg-contrast">
                  <header
                    className={`sticky top-0 flex items-center px-6 h-nav sm:px-8 md:px-12 ${
                      heading ? 'justify-between' : 'justify-end'
                    }`}
                  >
                    {heading !== null && (
                      <Dialog.Title>
                        <Heading
                          as="span"
                          size="lead"
                          id="cart-contents"
                          className={
                            'font-playfair desktop:text-36 laptop:text-36 tablet:text-28 mobile:text-18 old:text-18'
                          }
                        >
                          {heading}
                        </Heading>
                      </Dialog.Title>
                    )}
                  </header>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

/* Use for associating arialabelledby with the title*/
Drawer.Title = Dialog.Title;

export function useDrawer(openDefault = false) {
  const [isOpen, setIsOpen] = useState(openDefault);

  function openDrawer() {
    setIsOpen(true);
  }

  function closeDrawer() {
    setIsOpen(false);
  }

  return {
    isOpen,
    openDrawer,
    closeDrawer,
  };
}
