import type {Product} from '@shopify/hydrogen/storefront-api-types';

import {Heading, ProductCard, Section} from '~/components';

const mockProducts = new Array(12).fill('');

export function ProductSwimlane({
  title = 'Featured Products',
  products = mockProducts,
  count = 12,
  ...props
}: {
  title?: string;
  products?: Product[];
  count?: number;
}) {
  return (
    <Section
      className={`
        bg-violet
        desktop:!px-[100px] desktop:!py-[100px]
        laptop:!px-[100px] laptop: !py-[100px]
        tablet:!px-[80px] tablet:!py-[80px]
        mobile:!px-[15px] mobile:!py-[60px]
        old:!px-[15px] old:!py-[60px]
        border-b-[1px] !border-white !border-solid
      `}
      padding="y"
      {...props}
    >
      <Heading
        size={'lead'}
        className={
          'text-white font-playfair desktop:text-36 laptop:text-36 tablet:text-28 mobile:text-18 old: text-18'
        }
      >
        {title}
      </Heading>
      <div className="!px-0 swimlane hiddenScroll md:scroll-px-8 lg:scroll-px-12 md:px-8 lg:px-12">
        {products.map((product) => (
          <ProductCard
            product={product}
            key={product.id}
            className="snap-start w-80 text-white"
          />
        ))}
      </div>
    </Section>
  );
}
