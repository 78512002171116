import {useLocation} from '@remix-run/react';
import {COLOR, ROUTE} from '~/constants';
import {useEffect, useLayoutEffect, useState} from 'react';
import useWindowSize from '~/hooks/useResize';

interface IHeaderStyleTheme {
  left: COLOR;
  right: COLOR;
  position: 'relative' | 'absolute';
}
export const useHeaderStyles = (isMobileToggled?: boolean) => {
  const {pathname} = useLocation();
  const preProcessHeaderStyle: IHeaderStyleTheme = {
    left: COLOR.VIOLET,
    right: COLOR.VIOLET,
    position: 'relative',
  } satisfies IHeaderStyleTheme;

  const {width} = useWindowSize();

  switch (pathname) {
    case ROUTE.HOME: {
      preProcessHeaderStyle.left = COLOR.VIOLET;
      preProcessHeaderStyle.right = COLOR.WHITE;
      preProcessHeaderStyle.position = 'absolute';
      break;
    }
    case ROUTE.SUMISURA:
    case ROUTE.FAQ:
    case ROUTE.ABOUT: {
      preProcessHeaderStyle.left = COLOR.WHITE;
      preProcessHeaderStyle.right = COLOR.WHITE;
      preProcessHeaderStyle.position = 'absolute';
      break;
    }
    default: {
      preProcessHeaderStyle.left = COLOR.VIOLET;
      preProcessHeaderStyle.right = COLOR.VIOLET;
      preProcessHeaderStyle.position = 'relative';
      break;
    }
  }

  const [colors, setColors] = useState<IHeaderStyleTheme>(
    preProcessHeaderStyle,
  );

  useEffect(() => {
    if (isMobileToggled) {
      setColors({
        ...preProcessHeaderStyle,
        left: COLOR.VIOLET,
        right: COLOR.VIOLET,
      });

      return;
    }

    switch (pathname) {
      case ROUTE.HOME: {
        if (width < 768) {
          setColors({
            ...preProcessHeaderStyle,
            left: COLOR.VIOLET,
            right: COLOR.VIOLET,
          });
        } else {
          setColors({
            ...preProcessHeaderStyle,
            left: COLOR.VIOLET,
            right: COLOR.WHITE,
          });
        }
        break;
      }
      case ROUTE.SUMISURA:
      case ROUTE.FAQ:
      case ROUTE.ABOUT: {
        setColors({
          ...preProcessHeaderStyle,
          left: COLOR.WHITE,
          right: COLOR.WHITE,
        });
        break;
      }
      case ROUTE.CATALOG:
      case ROUTE.BLOG: {
        setColors({
          ...preProcessHeaderStyle,
          left: COLOR.VIOLET,
          right: COLOR.VIOLET,
        });
        break;
      }
      default: {
        setColors({
          ...preProcessHeaderStyle,
          left: COLOR.VIOLET,
          right: COLOR.VIOLET,
        });
        break;
      }
    }
  }, [width, pathname, isMobileToggled]);

  return colors;
};
