import {FC, useCallback, useState} from 'react';
import {Squeeze as Hamburger} from 'hamburger-react';
import {MenuDrawer} from '~/components/Layout';
import {EnhancedMenu, HeaderProps} from '~/components/new/Header';
import {useDrawer} from '~/components';
import {COLOR} from '~/constants';
export interface BurgerMenuButtonProps {
  color: string;
  menu?: EnhancedMenu;
  handleClick: () => void;
  handleClose: () => void;
  isOpen?: boolean;
}

const BurgerMenuButton: FC<BurgerMenuButtonProps> = ({
  color,
  menu,
  handleClick,
  handleClose,
  isOpen,
}) => {
  return (
    <div
      className={'flex lg:hidden md:hidden sm:flex z-51'}
      style={{zIndex: 51}}
    >
      {menu && (
        <MenuDrawer isOpen={!!isOpen} onClose={handleClose} menu={menu} />
      )}
      <Hamburger
        toggled={isOpen}
        toggle={handleClick}
        size={17}
        color={isOpen ? COLOR.VIOLET : color}
        onToggle={handleClick}
      />
    </div>
  );
};
export default BurgerMenuButton;
