const MinusIcon = () => {
  return (
    <svg
      className={`desktop:w-[16px] laptop:w-[16px] tablet:w-[16px] mobile:w-[10px] old:w-[10px]`}
      width="16"
      height="3"
      viewBox="0 0 16 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0312 0.643555H0.96875C0.882812 0.643555 0.8125 0.713867 0.8125 0.799805V1.97168C0.8125 2.05762 0.882812 2.12793 0.96875 2.12793H15.0312C15.1172 2.12793 15.1875 2.05762 15.1875 1.97168V0.799805C15.1875 0.713867 15.1172 0.643555 15.0312 0.643555Z"
        fill="#3A264B"
      />
    </svg>
  );
};

export default MinusIcon;
