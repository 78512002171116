import {EnhancedMenu, useIsHomePath} from '~/lib/utils';
import {Link, Section} from '~/components';
import {COLOR, ROUTE} from '~/constants';
import LogoIcon from '~/components/new/Icons/LogoIcon';
import FacebookIcon from '~/components/new/Icons/FacebookIcon';
import InstagramIcon from '~/components/new/Icons/InstagramIcon';

function Footer({menu}: {menu?: EnhancedMenu}) {
  const isHome = useIsHomePath();
  const itemsCount = menu
    ? menu?.items?.length + 1 > 4
      ? 4
      : menu?.items?.length + 1
    : [];

  return (
    <Section
      as="footer"
      role="contentinfo"
      style={{
        backgroundColor: COLOR.VIOLET,
        display: 'flex',
        columnGap: 0,
        flexFlow: 'wrap',
      }}
      className={`
          flex
          desktop:flex-row
          laptop:flex-row
          tablet:flex-col
          mobile:flex-col
          old:flex-col
          desktop:p-[100px]
          laptop:p-[100px]
          tablet:pt[50px] tablet:pr[15px] tablet:pb[15px]
          mobile:pt[50px] mobile:pr[15px] mobile:pb[15px]
          old:pt[50px] old:pr[15px] old:pb[15px]
          justify-between
          gap-y-[32px]
        `}
    >
      <div
        className={`
          flex
          flex-col
          text-start
          gap-y-[30px]
          desktop:w-[50%]
          laptop:w-[50%]
          tablet:w-[100%]
          mobile:w-[100%]
          old:w-[100%]
          justify-between
        `}
        style={{color: COLOR.WHITE}}
      >
        <LogoIcon color={COLOR.WHITE} />
        <div
          className={`
            font-graphik
            text-12
            uppercase
            w-[240px]
          `}
        >
          L’intimo artigianale. Pezzi unici ed esclusivi fatti a mano su misura.
        </div>

        <div
          className={`
            flex
            flex-row
            uppercase
            flex-nowrap
            whitespace-nowrap
            gap-x-[30px]
            desktop:justify-start laptop:justify-start tablet:justify-start mobile:justify-between old:justify-between
          `}
        >
          <div
            className={
              'flex flex-col gap-y-[8px] desktop:text-18 laptop:text-18 tablet:text-18 mobile:text-12 old:text-12'
            }
          >
            <Link to={ROUTE.ABOUT} className={'font-graphik'}>
              About
            </Link>
            <Link to={ROUTE.CATALOG} className={'font-graphik'}>
              Catalogo
            </Link>
            <Link to={ROUTE.SUMISURA} className={'font-graphik'}>
              Intimo su misura
            </Link>
          </div>

          <div
            className={
              'flex flex-col gap-y-[8px] desktop:text-18 laptop:text-18 tablet:text-18 mobile:text-12 old:text-12'
            }
          >
            <Link to={ROUTE.FAQ} className={'font-graphik'}>
              FAQ
            </Link>
            <Link to={ROUTE.PRIVACY_POLICY} className={'font-graphik'}>
              COOKIE POLICY
            </Link>
            <Link
              to={ROUTE.PRIVACY_POLICY}
              className={
                'font-graphik desktop:whitespace-nowrap laptop:whitespace-nowrap tablet:whitespace-nowrap mobile:whitespace-break-spaces old:whitespace-break-spaces'
              }
            >
              Informativa sulla privacy
            </Link>
          </div>
        </div>
      </div>

      <div
        className={`
        flex
        desktop:flex-col
        laptop:flex-col
        tablet:flex-col
        mobile:flex-col-reverse
        old:flex-col-reverse
        desktop:w-[50%]
        laptop:w-[50%]
        tablet:w-[100%]
        mobile:w-[100%]
        old:w-[100%]
        gap-y-[24px]
      `}
      >
        <div
          className={
            'flex gap-y-[12px] desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col old:flex-col justify-between'
          }
        >
          <div className={'flex flex-col gap-y-[8px] '}>
            <div className={'flex flex-col text-start'}>
              <span
                className={'font-graphik opacity-[0.5] text-14'}
                style={{color: COLOR.WHITE}}
              >
                Telefono
              </span>
              <span
                className={'font-graphik text-18'}
                style={{color: COLOR.WHITE}}
              >
                (39) 3319304934
              </span>
            </div>

            <div className={'flex flex-col text-start'}>
              <span
                className={'font-graphik text-14 opacity-[0.5]'}
                style={{color: COLOR.WHITE}}
              >
                Email
              </span>
              <span
                className={'font-graphik text-18'}
                style={{color: COLOR.WHITE}}
              >
                info@florieintimates.com
              </span>
            </div>
          </div>

          <div className={'flex'}>
            <div className={'flex gap-x-[15px] h-fit'}>
              <a
                className="rounded-full"
                href={'https://www.instagram.com/florieintimates/'}
              >
                <FacebookIcon />
              </a>
              <a
                className="rounded-full"
                href={'https://www.instagram.com/florieintimates/'}
              >
                <InstagramIcon />
              </a>
            </div>
          </div>
        </div>

        <div className={'flex flex-row gap-y-[16px] w-full'}>
          <div
            className={'flex flex-col gap-x-[8px] w-full text-start'}
            style={{color: COLOR.WHITE}}
          >
            <div
              className={
                'font-playfair desktop:text-18 laptop:text-18 tablet:text18 mobile:text16 old:text16 whitespace-nowrap'
              }
            >
              ISCRIVITI ALLA NOSTRA NEWSLETTER
            </div>
            <div
              className={
                'flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col old:flex-col flex-nowrap gap-y-[10px] gap-x-[16px]'
              }
            >
              <input
                type="email"
                className={
                  'flex h-full rounded-[3px] desktop:w-[60%] laptop:w-[60%] tablet:w-[100%] mobile:w-[100%] old:w-[100%] desktop:min-h-[60px] laptop:min-h-[60px] tablet:min-h-[45px] mobile:min-h-[45px] old:min-h-[45px]'
                }
                style={{backgroundColor: COLOR.WHITE, color: 'black'}}
                placeholder={'E-mail'}
              />
              <button
                className={
                  'desktop:w-[40%] laptop:w-[40%] tablet:w-[100%] mobile:w-[100%] old:w-[100%] text-center font-graphik desktop:min-h-[60px] laptop:min-h-[60px] tablet:min-h-[45px] mobile:min-h-[45px] old:min-h-[45px]'
                }
                style={{backgroundColor: COLOR.PURPLE_DARK, color: COLOR.WHITE}}
              >
                ISCRIVITI
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          'flex desktop:flex-row laptop:flex-row tablet:flex-col-reverse mobile:flex-col-reverse old:flex-col-reverse gap-y-[10px] opacity-[0.5] items-baseline w-full justify-between'
        }
        style={{color: COLOR.WHITE}}
      >
        <span className={'font-graphik uppercase text-12'}>
          FLORIE INTIMATES SRL P. IVA: 03250150590 Italy
        </span>
        <div className={'flex font-graphik max-w-[300px] text-18 text-start'}>
          Indirizzo postale via A. Rappini 5 Latina 04100 Italia
        </div>
      </div>
    </Section>
    // <Section
    //   divider={isHome ? 'none' : 'top'}
    //   as="footer"
    //   role="contentinfo"
    //   className={`grid min-h-[25rem] items-start grid-flow-row w-full gap-6 py-8 px-6 md:px-8 lg:px-12 md:gap-8 lg:gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-${itemsCount}
    //     bg-primary dark:bg-contrast dark:text-primary text-contrast overflow-hidden`}
    // >
    //   <FooterMenu menu={menu} />
    //   <CountrySelector />
    //   <div
    //     className={`self-end pt-8 opacity-50 md:col-span-2 lg:col-span-${itemsCount}`}
    //   >
    //     &copy; {new Date().getFullYear()} / Shopify, Inc. Hydrogen is an MIT
    //     Licensed Open Source project.
    //   </div>
    // </Section>
  );
}

export default Footer;
