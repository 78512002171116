import clsx from 'clsx';
import {Fragment, useRef} from 'react';
import {useScroll} from 'react-use';
import {flattenConnection, Image, Money} from '@shopify/hydrogen';
import type {
  Cart as CartType,
  CartCost,
  CartLine,
  CartLineUpdateInput,
} from '@shopify/hydrogen/storefront-api-types';
import {useFetcher} from '@remix-run/react';

import {
  Button,
  Heading,
  IconRemove,
  Text,
  Link,
  FeaturedProducts,
} from '~/components';
import {getInputStyleClasses} from '~/lib/utils';
import {CartAction} from '~/lib/type';
import {COLOR} from '~/constants';
import {IconButton, Select} from '@material-tailwind/react';
import SelectArrowIcon from '~/components/new/Icons/SelectArrowIcon';
import MinusIcon from '~/components/new/Icons/MinusIcon';
import PlusIcon from '~/components/new/Icons/PlusIcon';
type Layouts = 'page' | 'drawer';

export function Cart({
  layout,
  onClose,
  cart,
  onlyCheckout,
}: {
  layout: Layouts;
  onClose?: () => void;
  cart: CartType | null;
  onlyCheckout?: boolean;
}) {
  const linesCount = Boolean(cart?.lines?.edges?.length || 0);

  return (
    <>
      <CartEmpty hidden={linesCount} onClose={onClose} layout={layout} />
      <CartDetails cart={cart} layout={layout} onlyCheckout={onlyCheckout} />
    </>
  );
}

export function CartDetails({
  layout,
  cart,
  onlyCheckout,
}: {
  layout: Layouts;
  onlyCheckout?: boolean;
  cart: CartType | null;
}) {
  // @todo: get optimistic cart cost
  const cartHasItems = !!cart && cart.totalQuantity > 0;
  const container = {
    drawer: 'grid grid-cols-1 h-screen-no-nav grid-rows-[1fr_auto] overflow-auto',
    page: `w-full pb-12 grid md:grid-cols-2 md:items-start gap-8 md:gap-8 lg:gap-12`,
  };

  return (
    <div
      className={`${container[layout]} ${onlyCheckout ? 'w-full' : ''}`}
      id={'test'}
    >
      <div className={'flex flex-col gap-[14px]'}>
        { layout === 'page' && <Text className={'text-violet font-playfair desktop:text-36 laptop:text-36 tablet:text-28 mobile:text-18 old:text-18'}>Carrello</Text> }
        {!onlyCheckout && <CartLines lines={cart?.lines} layout={layout} />}
      </div>
      {cartHasItems && (
        <div className={`flex flex-col gap-[14px] ${ layout === 'page' ? 'desktop:ml-[15%] laptop:ml-[15%] tablet:ml-[15%]': ''}`}>
          { layout === 'page' && <Text className={'whitespace-nowrap text-violet font-playfair desktop:text-36 laptop:text-36 tablet:text-28 mobile:text-18 old:text-18'}>Riepilogo dell’ordine</Text> }
          <CartSummary cost={cart.cost} layout={layout}>
            <CartDiscounts discountCodes={cart.discountCodes} />
            <CartCheckoutActions checkoutUrl={cart.checkoutUrl} />
            {layout === 'drawer' && (
              <a
                href={'/cart'}
                className={
                  ' flex items-center justify-center desktop:h-[50px] laptop:h-[50px] tablet:h-[45px] mobile:h-[35px] old:h-[35px] w-full border text-center border-violet uppercase font-graphik desktop:text-16 laptop:text-16 mobile:text-12 old:text-12'
                }
              >
                vai al Carrello
              </a>
            )}
          </CartSummary>
        </div>
      )}
    </div>
  );
}

/**
 * Temporary discount UI
 * @param discountCodes the current discount codes applied to the cart
 * @todo rework when a design is ready
 */
function CartDiscounts({
  discountCodes,
}: {
  discountCodes: CartType['discountCodes'];
}) {
  const codes = discountCodes?.map(({code}) => code).join(', ') || null;

  return (
    <>
      {/* Have existing discount, display it with a remove option */}
      <dl className={codes ? 'grid' : 'hidden'}>
        <div className="flex items-center justify-between font-medium">
          <Text as="dt">Discount(s)</Text>
          <div className="flex items-center justify-between">
            <UpdateDiscountForm>
              <button>
                <IconRemove
                  aria-hidden="true"
                  style={{height: 18, marginRight: 4}}
                />
              </button>
            </UpdateDiscountForm>
            <Text as="dd">{codes}</Text>
          </div>
        </div>
      </dl>

      {/* No discounts, show an input to apply a discount */}
      <UpdateDiscountForm>
        <div
          className={clsx(
            codes ? 'hidden' : 'flex',
            'items-center gap-4 justify-between text-copy',
          )}
        >
          <input
            className={`${getInputStyleClasses()} flex-4 desktop:h-[50px] laptop:h-[50px] tablet:h-[45px] mobile:h-[35px] old:h-[35px] rounded-none`}
            type="text"
            name="discountCode"
            placeholder="INSERISCI IL CODICE PROMO"
          />
          <button className=" justify-center flex-2 items-center desktop:h-[50px] laptop:h-[50px] tablet:h-[45px] mobile:h-[35px] old:h-[35px] flex h-full text-white justify-end font-medium whitespace-nowrap bg-purpleDark font-graphik uppercase desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-12 old:text-12">
            Applica
          </button>
        </div>
      </UpdateDiscountForm>
    </>
  );
}

function UpdateDiscountForm({children}: {children: React.ReactNode}) {
  const fetcher = useFetcher();
  return (
    <fetcher.Form action="/cart" method="post">
      <input
        type="hidden"
        name="cartAction"
        value={CartAction.UPDATE_DISCOUNT}
      />
      {children}
    </fetcher.Form>
  );
}

function CartLines({
  layout = 'drawer',
  lines: cartLines,
}: {
  layout: Layouts;
  lines: CartType['lines'] | undefined;
}) {
  const currentLines = cartLines ? flattenConnection(cartLines) : [];
  const scrollRef = useRef(null);
  const {y} = useScroll(scrollRef);

  const className = clsx([
    y > 0 ? 'border-t' : '',
    layout === 'page'
      ? 'flex-grow md:translate-y-4'
      : 'px-6 pb-6 sm-max:pt-2 overflow-auto transition md:px-12',
  ]);

  return (
    <section
      ref={scrollRef}
      aria-labelledby="cart-contents"
      className={className}
    >
      <ul className="grid gap-6 md:gap-10">
        {currentLines.map((line, index) => (
          <CartLineItem
            key={line.id}
            line={line as CartLine}
            position={index + 1}
          />
        ))}
      </ul>
    </section>
  );
}

function CartCheckoutActions({checkoutUrl}: {checkoutUrl: string}) {
  if (!checkoutUrl) return null;

  return (
    <div className="flex flex-col mt-2">
      {/*<a href={checkoutUrl} target="_self">*/}
      <a href={checkoutUrl} target="_self">
        <Button
          as="span"
          width="full"
          className={
            'desktop:leading-[inherit] laptop:leading-[inherit] tablet:leading-[inherit] uppercase font-graphik desktop:text-16 laptop:text-16 tablet:text-16 mobile:text-12 old:text-12 rounded-none desktop:h-[50px] laptop:h-[50px] tablet:h-[45px] mobile:h-[35px] old:h-[35px]'
          }
          style={{backgroundColor: COLOR.VIOLET}}
        >
          Procedi al checkout
        </Button>
      </a>
      {/*<ShopPayButton  />*/}
    </div>
  );
}

function CartSummary({
  cost,
  layout,
  children = null,
}: {
  children?: React.ReactNode;
  cost: CartCost;
  layout: Layouts;
}) {
  const summary = {
    drawer: 'grid gap-4 p-6 border-t md:px-12',
    page: 'sticky top-nav grid gap-6 p-4 md:px-6 md:translate-y-4 bg-primary/5 rounded w-full',
  };

  return (
    <section
      aria-labelledby="summary-heading"
      className={`
      h-fit
      ${summary[layout]} bg-white border border-[#3A264B4D] rounded-none
      desktop:px-[30px] laptop:px-[30px] tablet:px-[30px] mobile:px-[10px] old:px-[10px]
      desktop:pt-[45px] laptop:pt-[45px] tablet:pt-[35px] mobile:pt-[16px] old:pt-[16px]
      desktop:pb-[50px] laptop:pb-[50px] tablet:pb-[40px] mobile:pb-[20px] old:pb-[20px]
    `}
    >
      <h2 id="summary-heading" className="sr-only">
        Order summary
      </h2>
      <dl className="grid">
        <div
          className={
            'flex flex-col desktop:gap-[32px] laptop:gap-[32px] tablet:gap-[32px] mobile:gap-[20px] old:gap-[20px] text-violet'
          }
        >
          <div className="flex flex-col items-center justify-between font-graphik desktop:gap-[20px] laptop:gap-[20px] tablet:gap-[16px] mobile:gap-[5px] old:gap-[5px]">
            <div className={'flex w-full justify-between '}>
              <Text
                as="dt"
                className={
                  'uppercase desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-12 old:text-12'
                }
              >
                Subtotale
              </Text>
              <Text
                as="dd"
                data-test="subtotal"
                className={
                  'font-graphik desktop:text-18 laptop:text-18 tablet:text-16 mobile:text-12 old:text-12'
                }
              >
                {cost?.subtotalAmount?.amount ? (
                  <Money data={cost?.subtotalAmount} />
                ) : (
                  '-'
                )}
              </Text>
            </div>
            <div className={'flex w-full justify-between'}>
              <Text
                as="dt"
                className={
                  'font-graphik uppercase desktop:text-20 laptop:text-20 tablet:text-16 mobile:text-12 old:text-12'
                }
              >
                Spedizione
              </Text>
              <Text
                as="dd"
                data-test="subtotal"
                className={
                  'font-graphik desktop:text-18 laptop:text-18 tablet:text-16 mobile:text-12 old:text-12'
                }
              >
                Calcolate al checkout
              </Text>
            </div>
          </div>

          <div className={'w-full h-[1px] bg-[#3A264B4D]'}></div>
          <div className={'w-full flex justify-between'}>
            <span
              className={
                'uppercase desktop:text-20 laptop:text-20 tablet:text-20 mobile:text-12 old:text-12 text-violet font-semibold'
              }
            >
              Totale
            </span>
            <div className={'font-graphik flex flex-row flex-nowrap'}>
              <span
                className={
                  'desktop:text-14 laptop:text-14 tablet:text-14 mobile:text-12 old:text-12 text-[#3A264B4D]'
                }
              >
                IVA inclusa
              </span>
              &nbsp;
              <span>
                {cost?.subtotalAmount?.amount ? (
                  <Money
                    data={cost?.subtotalAmount}
                    className={
                      'desktop:text-18 laptop:text-18 tablet:text-18 mobile:text-12 old:text-12 font-semibold'
                    }
                  />
                ) : (
                  '-'
                )}
              </span>
            </div>
          </div>
        </div>
      </dl>
      {children}
    </section>
  );
}

function CartLineItem({line, position}: {line: CartLine; position: number}) {
  if (!line?.id) return null;

  const {id, quantity, merchandise} = line;

  if (typeof quantity === 'undefined' || !merchandise?.product) return null;

  return (
    <li
      key={id}
      className={`
      max-w-[620px]
      flex
      gap-8
      border-t-[1px]
      border-[${COLOR.VIOLET}4D]
      fadeIn
      delayedListFadeAnimation
      pt-4
      desktop: pb-18
      laptop: pb-18
      tablet: pb-16
    `}
    >
      <div className="flex-shrink">
        {merchandise.image && (
          <Image
            // width={110}
            // height={110}
            aspectRatio={'72/100'}
            data={merchandise.image}
            className={`
              object-cover object-center border rounded
              desktop:!max-w-[193px]
              laptop:!max-w-[193px]
              tablet:!max-w-[160px]
              mobile:!max-w-[90px]
              old:!max-w-[90px]
              min-w-[90px]
            `}
            alt={merchandise.title}
          />
        )}
      </div>

      <div className="flex justify-between flex-grow gap-[8px]">
        <div className="grid gap-2 justify-between">
          <div className={'flex flex-col'}>
            <div className={'flex flex-row gap-[16px]'}>
              <Text
                className={
                  'graphik text-violet desktop:!text-20 laptop:!text-20 tablet:!text-18 mobile:!text-12 old:!text-12 uppercase'
                }
              >
                {merchandise?.product?.handle ? (
                  <Link to={`/products/${merchandise.product.handle}`}>
                    {merchandise?.product?.title || ''}
                  </Link>
                ) : (
                  <Text>{merchandise?.product?.title || ''}</Text>
                )}
              </Text>
              <Text className={'desktop:hidden laptop:hidden'}>
                <CartLinePrice line={line} as="span" />
              </Text>
            </div>

            <div className="flex flex-row pb-2 desktop:gap-[36px] laptop:gap-[36px] tablet:gap-[36px] mobile:gap-[20px] old:gap-[20px]">
              {(merchandise?.selectedOptions || []).map((option, index) => (
                <div
                  key={index}
                  className={'flex flex-row items-center gap-[10px] opacity-40'}
                >
                  <Text
                    className={
                      'uppercase font-graphik desktop:text-18 laptop:text-18 tablet:text-16 mobile:text-10 old:text-10'
                    }
                  >
                    taglia
                  </Text>
                  <DisabledSelect value={option.value} />
                </div>
                // <Text color="subtle" key={option.name}>
                //   <DisabledSelect value={option.value} />
                //   {option.name}: {option.value}
                // </Text>
              ))}
              <CartLineQuantityAdjust line={line} />
            </div>
          </div>
          <div className="flex items-center h-fit gap-2 self-end">
            {/*<div className="flex justify-start text-copy">*/}
            {/*  <CartLineQuantityAdjust line={line} />*/}
            {/*</div>*/}
            <ItemRemoveButton lineIds={[id]} />
          </div>
        </div>
        <Text className={'mobile:hidden old:hidden tablet:hidden'}>
          <CartLinePrice line={line} as="span" />
        </Text>
      </div>
    </li>
  );
}

const DisabledSelect = ({value}: {value: string}) => {
  return (
    <Select
      variant="static"
      containerProps={{className: '!max-w-[100px] !min-w-fit'}}
      className={`
      whitespace-nowrap
      justify-end
      flex
      p-0 flex-row gap-x-2
      border-0 uppercase [&>span]:pt-0 [&>div]:relative [&>div]:right-0 [&>span]:relative
      font-graphik
      text-violet
      desktop:text-18
      laptop:text-18
      tablet:text-16
      mobile:text-10
      old:text-10
      `}
      menuProps={{className: 'uppercase p-0'}}
      arrow={<SelectArrowIcon />}
      value={value}
      disabled
      labelProps={{className: 'after:hidden'}}
    >
      <Fragment></Fragment>
    </Select>
  );
};
function ItemRemoveButton({lineIds}: {lineIds: CartLine['id'][]}) {
  const fetcher = useFetcher();

  return (
    <fetcher.Form action="/cart" method="post">
      <input
        type="hidden"
        name="cartAction"
        value={CartAction.REMOVE_FROM_CART}
      />
      <input type="hidden" name="linesIds" value={JSON.stringify(lineIds)} />
      <button
        className="flex items-center justify-center border rounded"
        type="submit"
      >
        <span
          className={`
            font-graphik desktop:text-18 laptop:text-18 tablet:text-16 mobile:text-10 old:text-10 text-violet opacity-40
            uppercase
            underline underline-offset-[3px]
          `}
        >
          rimuovi
        </span>
        {/*<IconRemove aria-hidden=`true" />*/}
      </button>
    </fetcher.Form>
  );
}

function CartLineQuantityAdjust({line}: {line: CartLine}) {
  if (!line || typeof line?.quantity === 'undefined') return null;
  const {id: lineId, quantity} = line;
  const prevQuantity = Number(Math.max(0, quantity - 1).toFixed(0));
  const nextQuantity = Number((quantity + 1).toFixed(0));

  return (
    <>
      <label htmlFor={`quantity-${lineId}`} className="sr-only">
        Quantity, {quantity}
      </label>
      <div className="flex items-center rounded opacity-40">
        <UpdateCartButton lines={[{id: lineId, quantity: prevQuantity}]}>
          <IconButton
            className={`mobile:w-[16px] old:w-[16px]`}
            type={'submit'}
            disabled={quantity <= 1}
            variant="text"
            value={prevQuantity}
            name="increase-quantity"
            aria-label="Increase quantity"
          >
            <MinusIcon />
          </IconButton>
          {/*<button*/}
          {/*  name="decrease-quantity"*/}
          {/*  aria-label="Decrease quantity"*/}
          {/*  className="w-10 h-10 transition text-primary/50 hover:text-primary disabled:text-primary/10"*/}
          {/*  value={prevQuantity}*/}
          {/*  disabled={quantity <= 1}*/}
          {/*>*/}
          {/*  <MinusIcon />*/}
          {/*</button>*/}
        </UpdateCartButton>

        <div className={`px-2 font-graphik desktop:text-14 laptop:text-14 tablet:text-14 mobile:text-10 old:text-10 text-center`} data-test="item-quantity">
          {quantity}
        </div>

        <UpdateCartButton lines={[{id: lineId, quantity: nextQuantity}]}>
          <IconButton
            className={`mobile:w-[16px] old:w-[16px]`}
            type={'submit'}
            variant="text"
            value={nextQuantity}
            name="increase-quantity"
            aria-label="Increase quantity"
          >
            <PlusIcon />
          </IconButton>
          {/*<button*/}
          {/*  className="w-[20px] h-[20px] transition border-0 !bg-transparent !p-0 text-center font-graphik !text-18 text-violet"*/}
          {/*  name="increase-quantity"*/}
          {/*  value={nextQuantity}*/}
          {/*  aria-label="Increase quantity"*/}
          {/*>*/}
          {/*  <PlusIcon />*/}
          {/*</button>*/}
        </UpdateCartButton>
      </div>
    </>
  );
}

function UpdateCartButton({
  children,
  lines,
}: {
  children: React.ReactNode;
  lines: CartLineUpdateInput[];
}) {
  const fetcher = useFetcher();

  return (
    <fetcher.Form action="/cart" method="post">
      <input type="hidden" name="cartAction" value={CartAction.UPDATE_CART} />
      <input type="hidden" name="lines" value={JSON.stringify(lines)} />
      {children}
    </fetcher.Form>
  );
}

function CartLinePrice({
  line,
  priceType = 'regular',
  ...passthroughProps
}: {
  line: CartLine;
  priceType?: 'regular' | 'compareAt';
  [key: string]: any;
}) {
  if (!line?.cost?.amountPerQuantity || !line?.cost?.totalAmount) return null;

  const moneyV2 =
    priceType === 'regular'
      ? line.cost.totalAmount
      : line.cost.compareAtAmountPerQuantity;

  if (moneyV2 == null) {
    return null;
  }

  return (
    <div
      className={`
        flex flex-row gap-1 text-violet font-semibold font-graphik
        desktop:text-18 laptop:text-18 tablet:text-16 mobile:text-12 old:text-12
        `}
    >
      <Money
        withoutTrailingZeros
        withoutCurrency
        {...passthroughProps}
        data={moneyV2}
      />
      <span className={'lowercase'}>{moneyV2.currencyCode}</span>
    </div>
  );
}

export function CartEmpty({
  hidden = false,
  layout = 'drawer',
  onClose,
}: {
  hidden: boolean;
  layout?: Layouts;
  onClose?: () => void;
}) {
  const scrollRef = useRef(null);
  const {y} = useScroll(scrollRef);

  const container = {
    drawer: clsx([
      'content-start gap-4 px-6 pb-8 transition overflow-y-scroll md:gap-12 md:px-12 h-screen-no-nav md:pb-12 absolute',
      y > 0 ? 'border-t' : '',
    ]),
    page: clsx([
      hidden ? '' : 'grid',
      `pb-12 w-full md:items-start gap-4 md:gap-8 lg:gap-12`,
    ]),
  };

  return (
    <div ref={scrollRef} className={container[layout]} hidden={hidden}>
      <section className="grid gap-6">
        <Text format>
          Looks like you haven&rsquo;t added anything yet, let&rsquo;s get you
          started!
        </Text>
        <div>
          <Button onClick={onClose}>Continue shopping</Button>
        </div>
      </section>
      <section className="grid gap-8 pt-16">
        <FeaturedProducts
          count={4}
          heading="Shop Best Sellers"
          layout={layout}
          onClose={onClose}
          sortKey="BEST_SELLING"
        />
      </section>
    </div>
  );
}
